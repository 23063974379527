<!------------------------------------------------------------------------------
// KIDIWI Digital property
// This component is used to display the list of request done to the SP
// Props: None
// Events:
// - alertSnackBarMessage: return an event to the parent with the following data: { message: this.consentOK, snackbarTimeout: -1 }
// Component:
    <kdw-request-management />
// ------------------------------------------------------------------------------>

<template>
  <div>
    <!------------  Display for mobile -------->
    <v-row
      class="hidden-md-and-up"
    >
      <v-col
        cols="12"
        sm="12"
      >
        <v-card
          v-show="displayServices"
          elevation="11"
          class="mx-auto my-12 rounded"
          shaped
        >
          <v-system-bar
            color="primary"
            class="grey--text text--lighten-3"
          >
            <v-icon
              class="ml-1 grey--text text--lighten-3"
            >
              mdi-fountain-pen-tip
            </v-icon>
            <span class="font-weight-bold">SERVICE REQUEST LIST</span>
          </v-system-bar>
          <v-card-text
            v-for="(requestItem, r) in request.requests"
            :key="r"
          >
            <v-divider
              v-show="r !== 0"
              light
            />
            <!------- Main title  ---->
            <v-list-item-title
              class="primary--text ml-4 mt-4"
              style="white-space: normal"
            >
              <strong>{{ requestItem.serviceName }}</strong>
            </v-list-item-title>
            <!------- Details of the Service Request  ---->
            <v-list-item>
              <v-list-item-content
                style="white-space: normal"
                class="concrete--text"
              >
                <!-- Display the state --->
                <v-list-item-subtitle>
                  <v-icon
                    small
                  >
                    mdi-arrange-send-to-back
                  </v-icon>
                  <span style="white-space: normal">{{ convertStateName(requestItem.state.currentState) }}</span>
                </v-list-item-subtitle>
                <!--- display the type : fixed or quotation --->
                <v-list-item-subtitle
                  class="jean--text"
                >
                  <v-icon
                    small
                    color="jean"
                  >
                    mdi-circle
                  </v-icon>
                  {{ (requestItem.isFixedPrice) ? $t('marketSP.fixedPrice') : $t('marketSP.quotationBased') }}
                </v-list-item-subtitle>
                <!-- mdi-check-circle -->
                <!-- Display the request date -->
                <v-list-item-subtitle>
                  <!-- <v-icon small>mdi-calendar-import</v-icon>{{ $t('RequestEU.dateRequest') }}: {{ requestItem.createdAt }} -->
                  <v-icon small>
                    mdi-calendar-import
                  </v-icon>{{ requestItem.createdAt.substring(0, 10) }}
                </v-list-item-subtitle>
                <!-- Display the expiration date -->
                <v-list-item-subtitle>
                  <!-- <v-icon small>mdi-calendar-end</v-icon>{{ $t('RequestEU.expirationDate') }}: {{ requestItem.datetimeExpiry }} -->
                  <v-icon small>
                    mdi-calendar-end
                  </v-icon> {{ requestItem.datetimeExpiry.substring(0, 10) }}
                </v-list-item-subtitle>
                <!-- Display the appointment date -->
                <v-list-item-subtitle>
                  <v-btn
                    v-show="(requestItem.appointmentId === '') && (requestItem.onlineBooking === true) && (requestItem.state.isAppointmentButtonDisplayed())"
                    rounded
                    small
                    @click="addAppointment(requestItem)"
                  >
                    <v-icon
                      colors="jean"
                      small
                    >
                      mdi-calendar
                    </v-icon>
                    <span class="jean--text">{{ $t('RequestEU.addAppoint') }}</span>
                  </v-btn>
                  <v-menu
                    v-if="requestItem.appointmentId !== ''"
                    offset-y
                  >
                    <template #activator="{ on, attrs }">
                      <v-btn
                        small
                        colors="jean"
                        v-bind="attrs"
                        rounded
                        outlined
                        v-on="on"
                      >
                        <v-icon
                          small
                          color="jean"
                        >
                          mdi-calendar
                        </v-icon>
                        <span class="jean--text">{{ requestItem.appointmentDateTime }}</span>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item>
                        <v-list-item-title>
                          <v-btn
                            @click="openDialogResourceInfo(requestItem)"
                          >
                            <v-icon
                              center
                            >
                              mdi-account
                            </v-icon>
                            {{ $t('RequestEU.resource') }}
                          </v-btn>
                        </v-list-item-title>
                        <v-list-item-title>
                          <v-btn
                            @click="deleteAppointment(requestItem)"
                          >
                            <v-icon
                              center
                            >
                              mdi-delete
                            </v-icon>
                            {{ $t('RequestEU.delAppoint') }}
                          </v-btn>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-list-item-subtitle>
              </v-list-item-content>
              <!-- We are displaying the price and the action list -->
              <v-list-item-group
                class="mr-n9"
              >
                <v-list-item-action>
                  <!--
                  <v-chip>
                    {{ convertStateName(requestItem.state.currentState) }}
                  </v-chip>
                  -->
                  <kdw-label
                    :text="displayServicePrice(requestItem.isFixedPrice, requestItem.servicePrice)"
                  />
                  <v-menu
                    bottom
                  >
                    <template #activator="{ on, attrs }">
                      <v-btn
                        icon
                        colors="primary"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <!-- Delete -->
                      <v-list-item
                        v-show="(requestItem.state.isDeleteButtonDisplayed()) && !(requestItem.state.isDisabled())"
                      >
                        <kdw-btn
                          color="sunflower"
                          elevation="10"
                          min-width="50"
                          x-small
                          @click="deleteRequest(requestItem)"
                        >
                          <v-icon
                            color="white"
                            x-small
                          >
                            mdi-delete
                          </v-icon>
                          {{ $t('marketSP.delete') }}
                        </kdw-btn>
                      </v-list-item>
                      <!-- Display Questions -->
                      <v-list-item
                        v-show="requestItem.qna !== null"
                      >
                        <kdw-btn
                          color="sunflower"
                          elevation="10"
                          min-width="50"
                          x-small
                          @click="editQuestions(requestItem)"
                        >
                          <v-icon
                            color="white"
                            x-small
                          >
                            mdi-help
                          </v-icon>
                          {{ $t('marketSP.questions') }}
                        </kdw-btn>
                      </v-list-item>
                      <!-- Onsite Quotation  -->
                      <v-list-item
                        v-show="(requestItem.isFixedPrice === false) && !(requestItem.state.isOnSiteButtonDisabled())"
                      >
                        <kdw-btn
                          color="sunflower"
                          elevation="10"
                          min-width="50"

                          x-small
                          @click="onSiteQuotation(requestItem)"
                        >
                          <v-icon
                            color="white"
                            x-small
                          >
                            mdi-walk
                          </v-icon>
                          {{ $t('marketSP.quotationOnsite') }}
                        </kdw-btn>
                      </v-list-item>
                      <!-- Edit Quotation -->
                      <v-list-item
                        v-show="(requestItem.isFixedPrice === false) && !(requestItem.state.isQuotationButtonDisabled())"
                      >
                        <kdw-btn
                          color="sunflower"
                          elevation="10"
                          min-width="50"
                          x-small
                          @click="editQuotation(requestItem)"
                        >
                          <v-icon
                            color="white"
                            x-small
                          >
                            mdi-currency-eur
                          </v-icon>
                          {{ $t('marketSP.editQuotation') }}
                        </kdw-btn>
                      </v-list-item>
                      <!-- Download quotation -->
                      <v-list-item
                        v-show="(requestItem.isFixedPrice === false) && !(requestItem.state.isQuotationPDFDisplayButtonDisabled())"
                      >
                        <kdw-btn
                          color="sunflower"
                          elevation="10"
                          min-width="50"
                          x-small
                          @click="openQuotation(requestItem)"
                        >
                          <v-icon
                            color="white"
                            x-small
                          >
                            mdi-file-pdf
                          </v-icon>
                          {{ $t('marketSP.downloadQuotation') }}
                        </kdw-btn>
                      </v-list-item>
                      <!-- Card Visit EU -->
                      <!-- v-show="requestItem.state.isCardAccountButtonDisplayed()" removed from v-list-item - Display the SP infos all the time -->
                      <v-list-item>
                        <kdw-btn
                          color="sunflower"
                          elevation="10"
                          min-width="50"
                          x-small
                          @click="openDialogCardInfo(requestItem)"
                        >
                          <v-icon
                            color="white"
                            x-small
                          >
                            mdi-card-account-details
                          </v-icon>
                          {{ $t('marketSP.contactEU') }}
                        </kdw-btn>
                      </v-list-item>
                      <!-- Validate the Request from EndUser  -->
                      <v-list-item
                        v-show="requestItem.state.isvalidateRequestButtonDisplayed()"
                      >
                        <kdw-btn
                          color="primary"
                          elevation="10"
                          min-width="50"
                          x-small
                          @click="validateFixedRequestEU(requestItem)"
                        >
                          <v-icon
                            color="white"
                            x-small
                          >
                            mdi-check-bold
                          </v-icon>
                          {{ $t('marketSP.Accept') }}
                        </kdw-btn>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-list-item-action>
              </v-list-item-group>
            </v-list-item>
            <v-list-item>
              <!-- We are taking care of the actions -->
              <!-----
              <v-spacer />
              <v-list-item-group>
                <-- Delete --
                <v-list-item-action
                  v-show="(requestItem.state.isDeleteButtonDisplayed()) && !(requestItem.state.isDisabled())"
                >
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <kdw-btn
                        color="sunflower"
                        elevation="10"
                        min-width="50"
                        v-bind="attrs"
                        v-on="on"
                        x-small
                        @click="deleteRequest(requestItem)"
                      >
                        <v-icon
                          color="white"
                          x-small
                        >
                          mdi-delete
                        </v-icon>
                        Delete
                      </kdw-btn>
                    </template>
                    <span>{{ $t('tooltip.deleteSP') }}</span>
                  </v-tooltip>
                </v-list-item-action>
                <-- Display Questions --
                <v-list-item-action
                  v-show="requestItem.qna !== null"
                >
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <kdw-btn
                        color="sunflower"
                        elevation="10"
                        min-width="50"
                        v-bind="attrs"
                        v-on="on"
                        x-small
                        @click="editQuestions(requestItem)"
                      >
                        <v-icon
                          color="white"
                          x-small
                        >
                          mdi-help
                        </v-icon>
                        Questions
                      </kdw-btn>
                    </template>
                    <span>{{ $t('tooltip.question') }}</span>
                  </v-tooltip>
                </v-list-item-action>
                <-- Onsite Quotation  --
                <v-list-item-action
                  v-show="(requestItem.isFixedPrice === false) && !(requestItem.state.isOnSiteButtonDisabled())"
                >
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <kdw-btn
                        color="sunflower"
                        elevation="10"
                        min-width="50"
                        v-bind="attrs"
                        v-on="on"
                        x-small
                        @click="onSiteQuotation(requestItem)"
                      >
                        <v-icon
                          color="white"
                          x-small
                        >
                          mdi-walk
                        </v-icon>
                        OnSite Quotation
                      </kdw-btn>
                    </template>
                    <span>{{ $t('tooltip.onSiteQuotation') }}</span>
                  </v-tooltip>
                </v-list-item-action>
                <-- Edit Quotation --
                <v-list-item-action
                  v-show="(requestItem.isFixedPrice === false) && !(requestItem.state.isQuotationButtonDisabled())"
                >
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <kdw-btn
                        color="sunflower"
                        elevation="10"
                        min-width="50"
                        v-bind="attrs"
                        v-on="on"
                        x-small
                        @click="editQuotation(requestItem)"
                      >
                        <v-icon
                          color="white"
                          x-small
                        >
                          mdi-currency-eur
                        </v-icon>
                        Your Quotation
                      </kdw-btn>
                    </template>
                    <span>{{ $t('tooltip.editQuotation') }}</span>
                  </v-tooltip>
                </v-list-item-action>
                <-- Download quotation --
                <v-list-item-action
                  v-show="(requestItem.isFixedPrice === false) && !(requestItem.state.isQuotationPDFDisplayButtonDisabled())"
                >
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <kdw-btn
                        color="sunflower"
                        elevation="10"
                        min-width="50"
                        v-bind="attrs"
                        v-on="on"
                        x-small
                        @click="openQuotation(requestItem)"
                      >
                        <v-icon
                          color="white"
                          x-small
                        >
                          mdi-file-pdf
                        </v-icon>
                        Download Quotation
                      </kdw-btn>
                    </template>
                    <span>{{ $t('tooltip.openQuotation') }}</span>
                  </v-tooltip>
                </v-list-item-action>
                <-- Card Visit EU --
                <v-list-item-action
                  v-show="requestItem.state.isCardAccountButtonDisplayed()"
                >
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <kdw-btn
                        color="sunflower"
                        elevation="10"
                        min-width="50"
                        v-bind="attrs"
                        v-on="on"
                        x-small
                        @click="openDialogCardInfo(requestItem)"
                      >
                        <v-icon
                          color="white"
                          x-small
                        >
                          mdi-card-account-details
                        </v-icon>
                        Contact
                      </kdw-btn>
                    </template>
                    <span>{{ $t('tooltip.cardVisitEU') }}</span>
                  </v-tooltip>
                </v-list-item-action>
              </v-list-item-group>
              -->
            </v-list-item>
          </v-card-text>
          <!---- We are taking care of the bottom of the card --->
          <v-divider />
          <v-card-actions
            class="mt-4"
          >
            <v-combobox
              :key="'ComboFilter1' + displayTrick"
              v-model="filterOptionsSelected"
              :items="getFilterOptions"
              item-text="name"
              item-value="id"
              :label="$t('generalSearchResults.filter')"
              light
              dense
              class="mx-2 ml-5"
              @change="filterOptionsChanged()"
            />
            <v-combobox
              :key="'ComboFilter2' + displayTrick"
              v-model="rowByPageSelected"
              :items="getRowByPage"
              item-text="name"
              item-value="id"
              :label="$t('generalSearchResults.rowsByPage')"
              class="mx-2"
              dense
              disabled
              light
            />
            <v-spacer />
            <v-chip
              disabled
              label
              class="mb-4"
            >
              1/1
            </v-chip>
            <kdw-btn
              min-width="10"
              small
              class="ml-3 mb-4"
              color="rgba(60,60,59,0.6)"
              disabled
            >
              <v-icon>mdi-chevron-left </v-icon>
            </kdw-btn>
            <kdw-btn
              min-width="10"
              small
              class="mr-5 mb-4"
              color="rgba(60,60,59,0.6)"
              disabled
            >
              <v-icon> mdi-chevron-right </v-icon>
            </kdw-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row
      v-show="!displayServices"
      class="primary--text"
    >
      <strong>{{ $t('requestMng.noServiceRequest') }}</strong>
      <v-spacer />
      <kdw-btn
        id="switch_MyServices"
        color="primary"
        @click="navigateMyServices()"
      >
        {{ $t('sideMenuSP.services') }}
      </kdw-btn>
    </v-row>
    <v-row>
      <!--- DIALOG BOX for DISPLAYING THE QUESTIONS -->
      <kdw-dialog-question-results
        v-if="editedItem.qna !== undefined"
        v-model="dialogQuestion"
        :question-item="editedItem.qna.questions"
      />
      <!-- V-DIALOG Card info SP-------------------------------------------->
      <kdw-dialog-infoeusp
        v-model="dialogCardInfo"
        :business-info-cpy-name="businessInfoCpyName"
        :business-info="businessInfo"
      />
      <!--- DIALOG BOX for Making the quotation -->
      <v-dialog
        v-model="dialogQuotation"
        max-width="500px"
        persistent
      >
        <v-card>
          <v-card-title>
            <base-info-card
              :title="$t('marketSP.proposeQuotation')"
              color="primary"
            />
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="6"
                >
                  <kdw-text-field
                    id="servicePriceTextQuotationDialog"
                    v-model="myAnswer.servicePrice"
                    :disabled="(editedItem.state !== undefined) ? editedItem.state.isDisabled() : false"
                    type="number"
                    :label="$t('RequestEU.price')"
                    prefix="€"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="6"
                >
                  <v-menu
                    ref="menuDate"
                    v-model="menuDate"
                    :close-on-content-click="false"
                    :return-value.sync="myAnswer.dateExpiry"
                    :disabled="(editedItem.state !== undefined) ? editedItem.state.isDisabled() : false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        v-model="myAnswer.dateExpiry"
                        :label="$t('marketSP.expirationDate')"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="myAnswer.dateExpiry"
                      no-title
                      scrollable
                    >
                      <v-spacer />
                      <kdw-btn
                        @click="menuDate = false"
                      >
                        {{ $t('button.cancel') }}
                      </kdw-btn>
                      <kdw-btn
                        @click="$refs.menuDate.save(myAnswer.dateExpiry)"
                      >
                        {{ $t('button.validateQuestion') }}
                      </kdw-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="7"
                  md="7"
                >
                  <kdw-text-field
                    v-model="myAnswer.quotation"
                    :label="$t('marketSP.quotationFile')"
                    :disabled="true"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="5"
                  md="5"
                >
                  <v-file-input
                    id="pickquotationFileInputQuotationDialog"
                    v-model="myAnswer.quotationFile"
                    :rules="rules"
                    :disabled="(editedItem.state !== undefined) ? editedItem.state.isDisabled() : false"
                    :placeholder="$t('marketSP.pickQuotation')"
                    dense
                    show-size
                    @change="pickQuotationFile"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-textarea
                    id="noteCustomerTextQuotationDialog"
                    v-model="myAnswer.noteCustomer"
                    :disabled="(editedItem.state !== undefined) ? editedItem.state.isDisabled() : false"
                    :label="$t('marketSP.noteCustomer')"
                    auto-grow
                    outlined
                    clearable
                    prepend-icon="mdi-comment-eye-outline"
                    rows="1"
                  />
                  <v-textarea
                    id="notePersonalTextQuotationDialog"
                    v-model="myAnswer.notePersonal"
                    :disabled="(editedItem.state !== undefined) ? editedItem.state.isDisabled() : false"
                    :label="$t('marketSP.noteInternal')"
                    auto-grow
                    outlined
                    clearable
                    prepend-icon="mdi-comment-minus"
                    rows="1"
                  />
                  <v-textarea
                    id="serviceLegalTextQuotationDialog"
                    v-model="myAnswer.serviceLegaldescription"
                    :disabled="(editedItem.state !== undefined) ? editedItem.state.isDisabled() : false"
                    :label="$t('marketSP.legalCustomer')"
                    auto-grow
                    outlined
                    clearable
                    prepend-icon="mdi-comment-flash-outline"
                    rows="1"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <kdw-btn
              id="cancelQuotationDialog"
              @click="cancelQuotation"
            >
              {{ $t('button.cancel') }}
            </kdw-btn>
            <v-spacer />
            <kdw-btn
              v-if="(editedItem.state !== undefined) ? editedItem.state.isSaveButtonQuotationDisplayed() : true"
              id="saveQuotationDialog"
              @click="saveQuotation"
            >
              {{ $t('button.validateQuestion') }}
            </kdw-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!--- DIALOG BOX for CANCELLING THE PROPOSAL -->
      <v-dialog
        v-model="dialogDelete"
        max-width="500px"
        persistent
      >
        <v-card>
          <v-card-text>
            <kdw-avatar-card
              :key="'myKey1'"
              :icon="'mdi-help-circle-outline'"
              :outlined="false"
              :title="'customerSP.cancelOffer'"
              color="transparent"
              horizontal
              space="0"
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <kdw-btn
              id="cancelDeleteRequestButton"
              text
              @click="cancelDelete"
            >
              {{ $t('button.cancel') }}
            </kdw-btn>
            <v-spacer />
            <kdw-btn
              id="confimDeleteRequestButton"
              @click="deleteRequestConfirmed"
            >
              {{ $t('button.deleteRequestEU') }}
            </kdw-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!--- DIALOG BOX for VALIDATING THE REQUEST APPROVED BY END USER -->
      <v-dialog
        v-model="dialogValidateRequestEU"
        max-width="500px"
        persistent
      >
        <v-card>
          <v-card-text>
            <kdw-avatar-card
              :key="'myKey1'"
              :icon="'mdi-help-circle-outline'"
              :outlined="false"
              :title="'customerSP.validateRequest'"
              color="transparent"
              horizontal
              space="0"
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <kdw-btn
              id="cancelValidateRequestButton"
              text
              @click="cancelValidate()"
            >
              {{ $t('button.cancel') }}
            </kdw-btn>
            <v-spacer />
            <kdw-btn
              id="confirmValidateRequestButton"
              @click="validateRequestConfirmed()"
            >
              {{ $t('button.accept') }}
            </kdw-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!--- DIALOG BOX FOR BOOKING -->
      <kdw-dialog-booking
        v-model="dialogOnlineBooking"
        :service-id="ServiceIDforOnlineBooking"
        :answer-id="answerId"
        :event="'spAnswerUpdateFromBooking;' + answerId"
        @spAnswerUpdateFromBooking="spAnswerUpdateFromBooking($event)"
      />
      <!-- V-DIALOG ressource info -------------------------------------------->
      <kdw-dialog-info-resource
        v-model="dialogResourceInfo"
        :title="$t('RequestEU.resourceName')"
        :callout="resourceFistLastName"
      />
    </v-row>
    <!------------  DISPLAY for DESKTOP -------->
    <v-row
      class="hidden-sm-and-down"
    >
      <v-data-table
        :headers="headersRequest"
        :items="request.requests"
        :search="search"
        sort-by="serviceName"
        class="elevation-1"
        dense
      >
        <!--  Changer l'ordre de select  sort-by="createdAt" , :class="[item.selected && 'selected']"-->
        <template #top>
          <v-toolbar
            flat
          >
            <v-toolbar-title>{{ $t('customerSP.serviceRequest') }}</v-toolbar-title>
            <v-divider
              class="mx-4"
              inset
              vertical
            />
            <v-spacer />
            <v-text-field
              v-model="search"
              :label="$t('hero.search')"
              class="mx-4"
            />
          </v-toolbar>
        </template>
        <template #item.servicePrice="{ item, index }">
          <span
            :id="'servicePriceSPTextDesktop' + index"
            class="primary--text"
          >
            <strong>{{ displayServicePrice(item.isFixedPrice, item.servicePrice, item) }}</strong>
          </span>
        </template>
        <!-- dispay Fixed price or not -->
        <template #item.isFixedPrice="{ item, index }">
          <v-simple-checkbox
            :id="'fixedPriceSPCheckboxDesktop' + index"
            v-model="item.isFixedPrice"
            disabled
          />
        </template>
        <!-- Display current state -->
        <template #item.state.currentState="{ item, index }">
          <v-chip
            :id="'currentStateSPChipDesktop' + index"
            dense
            label
            dark
          >
            {{ convertStateName(item.state.currentState) }}
          </v-chip>
        </template>
        <!--- Action for appointmentDateTime ------>
        <template #item.appointmentDateTime="{ item }">
          <v-btn
            v-show="(item.appointmentId === '') && (item.onlineBooking === true) && (item.state.isAppointmentButtonDisplayed())"
            rounded
            x-small
            color="jean"
            @click="addAppointment(item)"
          >
            <v-icon
              left
              color="white"
            >
              mdi-calendar
            </v-icon>
            {{ $t('RequestEU.addAppoint') }}
          </v-btn>
          <v-menu
            v-if="item.appointmentId !== ''"
            offset-y
          >
            <template #activator="{ on, attrs }">
              <v-btn
                small
                color="jean"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="white">
                  mdi-calendar
                </v-icon>
                <span class="white--text">{{ item.appointmentDateTime }}</span>
              </v-btn>
            </template>
            <v-list>
              <v-list-item>
                <v-list-item-title>
                  <v-btn
                    @click="openDialogResourceInfo(item)"
                  >
                    <v-icon
                      center
                    >
                      mdi-account
                    </v-icon>
                    {{ $t('RequestEU.resource') }}
                  </v-btn>
                </v-list-item-title>
                <v-list-item-title>
                  <v-btn
                    @click="deleteAppointment(item)"
                  >
                    <v-icon
                      center
                    >
                      mdi-delete
                    </v-icon>
                    {{ $t('RequestEU.delAppoint') }}
                  </v-btn>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <!-- We are taking care of the actions -->
        <template #item.actions="{ item, index }">
          <!-- Delete -->
          <v-tooltip
            v-if="(item.state.isDeleteButtonDisplayed()) && !(item.state.isDisabled())"
            bottom
          >
            <template #activator="{ on, attrs }">
              <v-icon
                :id="'deleteRequestSPButtonDesktop' + index"
                small
                class="mr-2"
                v-bind="attrs"
                v-on="on"
                @click="deleteRequest(item)"
              >
                mdi-delete
              </v-icon>
            </template>
            <span>{{ $t('tooltip.deleteSP') }}</span>
          </v-tooltip>
          <!-- Display Questions -->
          <v-tooltip
            v-if="item.qna !== null"
            bottom
          >
            <template #activator="{ on, attrs }">
              <v-icon
                :id="'editQuestionsSPButtonDesktop' + index"
                small
                class="mr-2"
                v-bind="attrs"
                v-on="on"
                @click="editQuestions(item)"
              >
                mdi-help
              </v-icon>
            </template>
            <span>{{ $t('tooltip.question') }}</span>
          </v-tooltip>
          <!-- Onsite Quotation  -->
          <v-tooltip
            v-if="(item.isFixedPrice === false) && !(item.state.isOnSiteButtonDisabled())"
            bottom
          >
            <template #activator="{ on, attrs }">
              <v-icon
                :id="'onSiteQuotationSPButtonDesktop' + index"
                small
                class="mr-2"
                v-bind="attrs"
                v-on="on"
                @click="onSiteQuotation(item)"
              >
                mdi-walk
              </v-icon>
            </template>
            <span>{{ $t('tooltip.onSiteQuotation') }}</span>
          </v-tooltip>
          <!-- Edit Quotation -->
          <v-tooltip
            v-if="(item.isFixedPrice === false) && !(item.state.isQuotationButtonDisabled())"
            bottom
          >
            <template #activator="{ on, attrs }">
              <v-icon
                :id="'editQuotationSPButtonDesktop' + index"
                small
                color="primary"
                class="mr-2"
                v-bind="attrs"
                v-on="on"
                @click="editQuotation(item)"
              >
                mdi-currency-eur
              </v-icon>
            </template>
            <span>{{ $t('tooltip.editQuotation') }}</span>
          </v-tooltip>
          <!-- Download quotation -->
          <v-tooltip
            v-if="(item.isFixedPrice === false) && !(item.state.isQuotationPDFDisplayButtonDisabled())"
            bottom
          >
            <template #activator="{ on, attrs }">
              <v-icon
                :id="'openQuotationSPButtonDesktop' + index"
                small
                class="mr-2"
                v-bind="attrs"
                v-on="on"
                @click="openQuotation(item)"
              >
                mdi-file-pdf
              </v-icon>
            </template>
            <span>{{ $t('tooltip.openQuotation') }}</span>
          </v-tooltip>
          <!-- Card Visit EU -->
          <!-- v-if="item.state.isCardAccountButtonDisplayed()" removed from v-tooltip - Display the SP infos all the time -->
          <v-tooltip
            bottom
          >
            <template #activator="{ on, attrs }">
              <v-icon
                :id="'openDialogCardInfoSPButtonDesktop' + index"
                small
                color="jean"
                v-bind="attrs"
                v-on="on"
                @click="openDialogCardInfo(item)"
              >
                mdi-card-account-details
              </v-icon>
            </template>
            <span>{{ $t('tooltip.cardVisitEU') }}</span>
          </v-tooltip>
          <!--  Validate Fixed price from EU "-->
          <v-tooltip
            v-if="item.state.isvalidateRequestButtonDisplayed()"
            bottom
          >
            <template #activator="{ on, attrs }">
              <v-icon
                :id="'validateRequestSPButtonDesktop' + index"
                small
                color="primary"
                v-bind="attrs"
                v-on="on"
                @click="validateFixedRequestEU(item)"
              >
                mdi-check-bold
              </v-icon>
            </template>
            <span>{{ $t('tooltip.validateEURequest') }}</span>
          </v-tooltip>
        </template>
        <template #no-data />
      </v-data-table>
    </v-row>
  </div>
</template>

<script>
  import { RequestService } from '@/services/Request'
  import { checkSPConsent, checkSPBanned } from '@/services/Consent'
  import { AnswerStateMachine, answerStateTranslation, answerState } from '@/services/StateMachine'
  import { formatServicePriceString, formatAWSDateTimeIntoDate } from '@/helpers/Formatter'
  import { FILEHEADERQUOTATION } from '@/services/File'
  import { downloadBlob } from '@/helpers/GenericFunctionsS3Download'
  import { provideExpirationDate } from '@/helpers/GenericFunctions'
  import { fetchLegalAndCustomerNote } from '@/services/Service'
  import { Logger, Storage } from 'aws-amplify'
  import { DateTime } from 'luxon'
  import { sendEmail } from '@/services/commonTools'
  import { navigate } from '@/helpers/Navigation'
  const logger = new Logger('RequestManagement2')

  export default {
    name: 'RequestManagement',

    // TODO translations
    data: () => ({
      request: null,
      displayServices: false,
      rowByPage: [],
      rowByPageSelected: { id: 'all' },
      filterOptions: [],
      filterOptionsSelected: { id: 'SERVICENAME↓' },
      displayTrick: 0,
      consentOK: '',
      editedIndex: 0,
      editedItem: {},
      editedRequestItemIndex: -1,
      editedRequestItem: {},
      dialogQuestion: false,
      dialogCardInfo: false,
      dialogValidateRequestEU: false,
      businessInfoCpyName: '',
      businessInfo: [
        {
          icon: 'mdi-map-marker-outline',
          title: 'Address',
          text: '1157 av de la plaine<br>06250 Mougins',
        },
        {
          icon: 'mdi-cellphone',
          title: 'Phone',
          text: '+33   66777',
        },
        {
          icon: 'mdi-email',
          title: 'Email',
          text: 'john@test.com',
        },
      ],
      dialogQuotation: false,
      myAnswer: { servicePrice: 0, dateExpiry: provideExpirationDate().substr(0, 10), serviceLegaldescription: '', noteCustomer: '', notePersonal: '', quotation: '', quotationUrl: '', quotationFile: null },
      myAnswerDefault: { servicePrice: 0, dateExpiry: provideExpirationDate().substr(0, 10), serviceLegaldescription: '', noteCustomer: '', notePersonal: '', quotation: '', quotationUrl: '', quotationFile: null },
      rules: [
        value => !value || value.size < 2000000 || this.$i18n.t('AccountSP.sizeLogo'),
      ],
      menuDate: false,
      dialogDelete: false,
      deleteItem: [],
      dialogOnlineBooking: false,
      ServiceIDforOnlineBooking: '',
      answerId: '',
      dialogResourceInfo: false,
      resourceFistLastName: '',
      search: '',
    }),

    computed: {
      getRowByPage: function () {
        return this.getRowByPageMethod()
      },
      getFilterOptions: function () {
        return this.getFilterOptionsMethod()
      },
      headersRequest: function () {
        return [
          {
            text: this.$i18n.t('RequestEU.serviceName'),
            align: 'start',
            sortable: false,
            value: 'serviceName',
          },
          // { text: this.$i18n.t('RequestEU.requester'), value: 'endUserId' },
          { text: this.$i18n.t('RequestEU.dateRequest'), value: 'createdAt' },
          { text: this.$i18n.t('RequestEU.fixedPrice'), value: 'isFixedPrice' },
          { text: this.$i18n.t('RequestEU.price'), value: 'servicePrice' },
          { text: this.$i18n.t('RequestEU.expirationDate'), value: 'datetimeExpiry' },
          { text: this.$i18n.t('RequestEU.appoint'), value: 'appointmentDateTime' },
          { text: this.$i18n.t('RequestEU.answerState'), value: 'state.currentState' },
          { text: this.$i18n.t('RequestEU.action'), value: 'actions', sortable: false },
        ]
      },
    },

    watch: {
    },

    created: function () {
      this.getFilterOptionsMethod()
      this.getRowByPageMethod()
      this.request = new RequestService()
      this.request.fetchRequestAndAnswersSP()
        .then(() => {
          if (this.request.length() > 0) {
            this.sortFoundServices()
            this.displayServices = true
            // console.log('this.request.requests:')
            // console.log(this.request.requests)
          }
        })
      checkSPConsent()
        .then(result => {
          this.consentOK = result
        })
    },

    methods: {
      navigateMyServices () {
        navigate(this, 'ServiceProviderMyServices') // goes SP --> My Services
      },
      displayServicePrice (isFixedPrice, ServicePrice) {
        return formatServicePriceString(isFixedPrice, ServicePrice, true)
      },
      convertStateName (originalName) {
        // console.log('originalName:', originalName)
        return answerStateTranslation('a_' + originalName)
      },
      // -------------------  MANAGE THE FILTER OPTIONS --------------------------------
      getRowByPageMethod: function () {
        const table = [{ id: '1', name: '1' },
                       { id: '2', name: '2' },
                       { id: '5', name: '5' },
                       { id: '10', name: '10' },
                       { id: '15', name: '15' },
                       { id: 'all', name: this.$i18n.t('generalSearchResults.all') }]
        if (this.rowByPageSelected.id === 'all') {
          this.rowByPageSelected.name = this.$i18n.t('generalSearchResults.all')
        }
        return table
      },
      getFilterOptionsMethod () {
        const table = [{ id: 'PRICE↑', name: this.$i18n.t('generalSearchResults.filterPriceUp') },
                       { id: 'PRICE↓', name: this.$i18n.t('generalSearchResults.filterPriceDown') },
                       { id: 'REQUESTDATE↑', name: this.$i18n.t('generalSearchResults.filterServiceRequestRequestDateUp') },
                       { id: 'REQUESTDATE↓', name: this.$i18n.t('generalSearchResults.filterServiceRequestRequestDateDown') },
                       { id: 'EXPIRATIONDATE↑', name: this.$i18n.t('generalSearchResults.filterServiceRequestExpiryDateUp') },
                       { id: 'EXPIRATIONDATE↓', name: this.$i18n.t('generalSearchResults.filterServiceRequestExpiryDateDown') },
                       { id: 'APPOINTMENTDATE↑', name: this.$i18n.t('generalSearchResults.filterServiceRequestAppointDateUp') },
                       { id: 'APPOINTMENTDATE↓', name: this.$i18n.t('generalSearchResults.filterServiceRequestAppointDateDown') },
                       { id: 'SERVICENAME↑', name: this.$i18n.t('generalSearchResults.filterServiceRequestNameUp') },
                       { id: 'SERVICENAME↓', name: this.$i18n.t('generalSearchResults.filterServiceRequestNameDown') }]
        // console.log('this.filterOptionsSelected before')
        // console.log(this.filterOptionsSelected)
        if (this.filterOptionsSelected.id === 'PRICE↑') {
          this.filterOptionsSelected.name = this.$i18n.t('generalSearchResults.filterPriceUp')
          return table
        }
        if (this.filterOptionsSelected.id === 'PRICE↓') {
          this.filterOptionsSelected.name = this.$i18n.t('generalSearchResults.filterPriceDown')
          return table
        }
        if (this.filterOptionsSelected.id === 'REQUESTDATE↑') {
          this.filterOptionsSelected.name = this.$i18n.t('generalSearchResults.filterServiceRequestRequestDateUp')
          return table
        }
        if (this.filterOptionsSelected.id === 'REQUESTDATE↓') {
          this.filterOptionsSelected.name = this.$i18n.t('generalSearchResults.filterServiceRequestRequestDateDown')
          return table
        }
        if (this.filterOptionsSelected.id === 'EXPIRATIONDATE↑') {
          this.filterOptionsSelected.name = this.$i18n.t('generalSearchResults.filterServiceRequestExpiryDateUp')
          return table
        }
        if (this.filterOptionsSelected.id === 'EXPIRATIONDATE↓') {
          this.filterOptionsSelected.name = this.$i18n.t('generalSearchResults.filterServiceRequestExpiryDateDown')
        }
        if (this.filterOptionsSelected.id === 'APPOINTMENTDATE↑') {
          this.filterOptionsSelected.name = this.$i18n.t('generalSearchResults.filterServiceRequestAppointDateUp')
          return table
        }
        if (this.filterOptionsSelected.id === 'APPOINTMENTDATE↓') {
          this.filterOptionsSelected.name = this.$i18n.t('generalSearchResults.filterServiceRequestAppointDateDown')
        }
        if (this.filterOptionsSelected.id === 'SERVICENAME↑') {
          this.filterOptionsSelected.name = this.$i18n.t('generalSearchResults.filterServiceRequestNameUp')
          return table
        }
        if (this.filterOptionsSelected.id === 'SERVICENAME↓') {
          this.filterOptionsSelected.name = this.$i18n.t('generalSearchResults.filterServiceRequestNameDown')
        }
        // console.log('this.filterOptionsSelected after')
        // console.log(this.filterOptionsSelected)
        this.displayTrick++
        return table
      },
      // Filter option changed
      filterOptionsChanged () {
        this.sortFoundServices()
      },
      // This is managing the ordering options of the Request content
      sortFoundServices () {
        // console.log('this.request')
        // console.log(this.request)
        switch (this.filterOptionsSelected.id) {
          case 'PRICE↑': // sort by price inverse (from big to small)
            this.request.requests.sort(function (a, b) {
              if (a.servicePrice < b.servicePrice) { return 1 }
              if (a.servicePrice > b.servicePrice) { return -1 }
              return 0
            })
            break
          case 'PRICE↓': // sort by price (from small to big)
            this.request.requests.sort(function (a, b) {
              if (a.servicePrice < b.servicePrice) { return -1 }
              if (a.servicePrice > b.servicePrice) { return 1 }
              return 0
            })
            break
          case 'REQUESTDATE↑':
            this.request.requests.sort((d1, d2) => new Date(d1.createdAt).getTime() - new Date(d2.createdAt).getTime())
            break
          case 'REQUESTDATE↓':
            this.request.requests.sort((d1, d2) => new Date(d2.createdAt).getTime() - new Date(d1.createdAt).getTime())
            break
          case 'EXPIRATIONDATE↑': // sort subCategory by alphabetic order inverse
            this.request.requests.sort((d1, d2) => new Date(d1.datetimeExpiry).getTime() - new Date(d2.datetimeExpiry).getTime())
            break
          case 'EXPIRATIONDATE↓': // sort subCategory by alphabetic order
            this.request.requests.sort((d1, d2) => new Date(d2.datetimeExpiry).getTime() - new Date(d1.datetimeExpiry).getTime())
            break
          case 'APPOINTMENTDATE↑': // sort subCategory by alphabetic order inverse
            this.request.requests.sort((d1, d2) => new Date(d1.appointmentDateTimeISO).getTime() - new Date(d2.appointmentDateTimeISO).getTime())
            break
          case 'APPOINTMENTDATE↓': // sort subCategory by alphabetic order
            this.request.requests.sort((d1, d2) => new Date(d2.appointmentDateTimeISO).getTime() - new Date(d1.appointmentDateTimeISO).getTime())
            break
          case 'SERVICENAME↑': // sort by price inverse (from big to small)
            this.request.requests.sort(function (a, b) {
              if (a.serviceName < b.serviceName) { return 1 }
              if (a.serviceName > b.serviceName) { return -1 }
              return 0
            })
            break
          case 'SERVICENAME↓': // sort by price (from small to big)
            this.request.requests.sort(function (a, b) {
              if (a.serviceName < b.serviceName) { return -1 }
              if (a.serviceName > b.serviceName) { return 1 }
              return 0
            })
            break
        }
      },
      // ------------------ MANAGE THE BUTTONS ---------------------------------
      deleteRequest (item) {
        if (this.consentOK !== '') {
          // this.$emit('alertSnackBarMessage', { message: this.consentOK, snackbarTimeout: -1 })
          // alert(this.consentOK)
          this.$root.kdwsnackbar.alert(this.consentOK, -1)
          return
        }
        // console.log('delete request')
        this.dialogDelete = true
        this.deleteItem.splice(0, this.deleteItem.length)
        this.deleteItem.push(item)
      },
      editQuestions (item) {
        // console.log('We pass here')
        if (this.consentOK !== '') {
          // this.$emit('alertSnackBarMessage', { message: this.consentOK, snackbarTimeout: -1 })
          this.$root.kdwsnackbar.alert(this.consentOK, -1)
          return
        }
        // console.log(item)
        this.editedIndex = this.request.requests.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogQuestion = true
      },
      onSiteQuotation (item) {
        if (this.consentOK !== '') {
          // this.$emit('alertSnackBarMessage', { message: this.consentOK, snackbarTimeout: -1 })
          // alert(this.consentOK)
          this.$root.kdwsnackbar.alert(this.consentOK, -1)
          return
        }
        this.request.onSiteQuotationRequest(item, this.request.requests.indexOf(item))
      },
      editQuotation (item) {
        if (this.consentOK !== '') {
          // this.$emit('alertSnackBarMessage', { message: this.consentOK, snackbarTimeout: -1 })
          this.$root.kdwsnackbar.alert(this.consentOK, -1)
          return
        }
        // console.log('editQuotation')
        // console.log('item:')
        // console.log(item)
        // console.log('this.request.requests')
        // console.log(this.request.requests)
        this.editedIndex = this.request.requests.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.editedItem.state = new AnswerStateMachine(item.state.currentState, item.isFixedPrice)
        // console.log('on regarde ce que lon recupere')
        // console.log('editedItem from editQuotation')
        // console.log(this.editedItem)
        this.myAnswer = Object.assign({}, this.myAnswerDefault)
        if (item.answer !== undefined) {
          this.myAnswer.servicePrice = item.answer.servicePrice
          this.myAnswer.serviceLegaldescription = item.answer.serviceLegaldescription
          this.myAnswer.noteCustomer = item.answer.noteCustomer
          this.myAnswer.notePersonal = item.answer.notePersonal
          this.myAnswer.dateExpiry = formatAWSDateTimeIntoDate(item.answer.datetimeExpiry)
          this.myAnswer.quotation = item.answer.quotation.substr(111 + FILEHEADERQUOTATION.length - 1)
        }
        if ((item.answer === undefined) || (item.state.currentState === answerState.onSiteQuotation)) {
          fetchLegalAndCustomerNote(item.serviceID, this.myAnswer)
            .then(() => {
              // console.log('this.myAnswer')
              // console.log(this.myAnswer)
            })
        }
        this.dialogQuotation = true
      },
      openQuotation (item) {
        if (this.consentOK !== '') {
          // this.$emit('alertSnackBarMessage', { message: this.consentOK, snackbarTimeout: -1 })
          this.$root.kdwsnackbar.alert(this.consentOK, -1)
          return
        }
        // console.log('openQuotation')
        // console.log(item)
        Storage.get(item.answer.quotation, {
          download: true,
          progressCallback (progress) {
            console.log(`Downloaded: ${progress.loaded}/${progress.total}`)
          },
        })
          .then(result => {
            var date = new Date().toISOString()
            const downloadFileName = item.serviceName + '_' + item.servicePrice + '_' + date.substring(0, 16) + '_' + item.answer.quotation.substr(111 + FILEHEADERQUOTATION.length - 1)
            downloadBlob(result.Body, downloadFileName)
          })
          .catch(err => logger.error(err))
      },
      openDialogCardInfo (item) {
        // console.log('openDialogCardInfo')
        if (this.consentOK !== '') {
          // this.$emit('alertSnackBarMessage', { message: this.consentOK, snackbarTimeout: -1 })
          this.$root.kdwsnackbar.alert(this.consentOK, -1)
          // alert(this.consentOK)
          return
        }
        // console.log('item')
        // console.log(item)
        // Need to be improved: this.businessInfoCpyName = item.endUser.firstname + ' ' + item.endUser.lastname
        this.businessInfoCpyName = ''
        if (item.endUser && item.endUser.firstname) {
          this.businessInfoCpyName = item.endUser.firstname
          if (item.endUser && item.endUser.lastname) {
            this.businessInfoCpyName = this.businessInfoCpyName + ' ' + item.endUser.lastname
          }
        } else {
          if (item.endUser && item.endUser.lastname) {
            this.businessInfoCpyName = item.endUser.lastname
          }
        }
        // Need to be improved: this.businessInfo[0].text = item.endUser.address1 + '<br>' + item.endUser.address2 + '<br>' + item.endUser.postcode + ' ' + item.endUser.postcodecity.townName + ' - ' + item.endUser.country.name
        this.businessInfo[0].text = ''
        if (item.endUser && item.endUser.address1) {
          this.businessInfo[0].text = this.businessInfo[0].text + item.endUser.address1
        }
        if (item.endUser && item.endUser.address2) {
          this.businessInfo[0].text = this.businessInfo[0].text + '<br>' + item.endUser.address2
        }
        if (item.endUser && item.endUser.postcode) {
          this.businessInfo[0].text = this.businessInfo[0].text + '<br>' + item.endUser.postcode
        }
        if (item.endUser && item.endUser.postcodecity && item.endUser.postcodecity.townName) {
          this.businessInfo[0].text = this.businessInfo[0].text + ' ' + item.endUser.postcodecity.townName
        }
        if (item.endUser && item.endUser.country && item.endUser.country.name) {
          this.businessInfo[0].text = this.businessInfo[0].text + ' - ' + item.endUser.country.name
        }
        // Need to be improved: this.businessInfo[1].text = item.endUser.phone
        if (item.endUser && item.endUser.phone) {
          this.businessInfo[1].text = item.endUser.phone
        } else {
          this.businessInfo[1].text = ''
        }
        // Need to be improved: this.businessInfo[2].text = item.endUser.email
        if (item.endUser && item.endUser.email) {
          this.businessInfo[2].text = item.endUser.email
        } else {
          this.businessInfo[2].text = ''
        }
        // console.log(this.businessInfo)
        this.dialogCardInfo = true
      },
      validateFixedRequestEU (item) {
        // console.log('validateFixedRequestEU')
        // We will open a window to manage the validation.
        this.dialogValidateRequestEU = true
        this.editedRequestItem = Object.assign({}, item)
        this.editedRequestItemIndex = this.request.requests.indexOf(item)
      },
      cancelValidate () {
        this.dialogValidateRequestEU = false
        this.editedRequestItem = {}
        this.editedRequestItemIndex = -1
      },
      validateRequestConfirmed () {
        console.log('validateRequestConfirmed', this.editedRequestItemIndex)
        console.log(this.editedRequestItem)
        this.request.requests[this.editedRequestItemIndex].state.acceptFixedRequestBySP(this.request.requests[this.editedRequestItemIndex].answer, this.editedRequestItem.serviceName, this.editedRequestItem.endUserId, this.request.requests[this.editedRequestItemIndex].serviceID)
        /*
        case 3: this.deleteItemAnswer[0].state.acceptQuoteByEU(this.deleteItemAnswer[0])
                  .then(() => {
                    // console.log(this.request)
                    // console.log(this.request.requests[this.dialogDeleteRequestPosition].answers[this.dialogDeleteAnswerPosition].state.currentState)
                    // console.log(this.deleteItemAnswer[0].state.currentState)
                    this.request.requests[this.dialogDeleteRequestPosition].answers[this.dialogDeleteAnswerPosition].state.currentState = this.deleteItemAnswer[0].state.currentState
                    this.deleteItemAnswer.splice(0, this.deleteItemAnswer.length)
                  })
        */
        this.cancelValidate()
      },
      // ----------------- MANAGE THE QUOTATION -------------------------------
      cancelQuotation () {
        this.dialogQuotation = false
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      },
      saveQuotation () {
        console.log('saveQuotation')
        if ((this.myAnswer.quotationFile === null) || (this.myAnswer.quotation === null)) {
          // this.$emit('alertSnackBarMessage', { message: this.$i18n.t('marketSP.alertNoQuotation'), snackbarTimeout: -1 })
          this.$root.kdwsnackbar.alert(this.$i18n.t('marketSP.alertNoQuotation'), -1)
          return
        }
        console.log('this.editedItem from saveQuotation:')
        console.log(this.editedItem)
        // console.log('this.editedItem.state')
        // console.log(this.editedItem.state)
        // console.log('this.myAnswer  from saveQuotation:')
        // console.log(this.myAnswer)
        // console.log('this.request  from saveQuotation:')
        // console.log(this.request)
        // console.log('this.editedIndex:')
        // console.log(this.editedIndex)
        this.request.createAnswerForRequest(this.editedItem, this.myAnswer, this.editedIndex)
          .then(() => {
            this.dialogQuotation = false
            // console.log('On va nettoyer l objet.... edited item')
            this.editedItem = Object.assign({}, this.defaultItem)
            // console.log('this.editedItem')
            // console.log(this.editedItem)
            this.editedIndex = -1
          })
          .catch(err => logger.error(err))
      },
      pickQuotationFile () {
        if (this.myAnswer.quotationFile !== null) {
          this.myAnswer.quotationUrl = URL.createObjectURL(this.myAnswer.quotationFile)
        }
      },
      // ----------------- MANAGE THE DELETE ---------------------------------
      cancelDelete () {
        this.dialogDelete = false
      },
      // perform the real cancel action to cancel the request
      deleteRequestConfirmed () {
        // console.log('deleteRequestConfirmed in ServiceproviderCustomerMarket')
        this.dialogDelete = false
        // console.log(myRequestObject)
        this.request.cancelledbySP(this.deleteItem[0])
      },
      // ----------------- MANAGE THE APPOINTMENT ---------------------------
      deleteAppointment (item) {
        // console.log('deleteAppointment')
        // console.log(item)
        // We search for the index
        const index = this.request.findIndex(item)
        // var appointmentDateTime = new Date(item.appointmentDateTime)
        // console.log('appointmentDateTime:', appointmentDateTime)
        // console.log('index in customer market:', index)
        if (index !== -1) {
          this.request.deleteAppointmentExecution(item.appointmentId, item.appointmentAnswerId, index)
            .then((result) => {
              // console.log(result)
              if (result) {
                var params = { serviceName: item.serviceName, date: DateTime.fromISO(item.appointmentDateTimeISO).toFormat('yyyy-LL-dd'), time: DateTime.fromISO(item.appointmentDateTimeISO).toFormat('HH:mm'), resourceId: item.resourceID, sendTo: 'RE' }
                sendEmail('bookingAppointmentDeletedResource', params)
                params.enduserId = item.endUserId
                params.sendTo = 'EU'
                sendEmail('bookingAppointmentDeletedResource', params)
              }
            })
        }
      },
      addAppointment (item) {
        // console.log('addAppointment')
        // console.log('item:')
        // console.log(item)
        if (item.serviceID !== '') {
          this.ServiceIDforOnlineBooking = item.serviceID
        } else {
          return
        }
        if (item.answer.id !== '') {
          this.answerId = item.answer.id
        } else {
          return
        }
        this.dialogOnlineBooking = true
      },
      spAnswerUpdateFromBooking (answerID) {
        // console.log('spAnswerUpdateFromBooking')
        this.request.refreshAppointmentDateAnswer(answerID)
      },
      // Display the resource name
      openDialogResourceInfo (item) {
        // console.log('open')
        this.dialogResourceInfo = true
        this.resourceFistLastName = item.resourceFirstname + ' ' + item.resourceLastname
      },
    },
  }
</script>
