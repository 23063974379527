import QnA from '@/services/QnA'
import { Logger } from 'aws-amplify'
import { RequestService } from '@/services/Request'
import { getUserSubSync as getUserSub } from '@/services/User'
import { navigate } from '@/helpers/Navigation'
import { fetchASubCategoryReturnQuestion, fetchACategoryReturnQuestion, fetchASubCategory } from '@/services/Category'
import { dealWithBooking } from './Booking'

const logger = new Logger('QuestionsController')

var readyItemsV1 = []

// Enduser or Anonymous selected a Service
export function pickItem (obj, item, postalCodeId, locale) {
  // console.log('pickItem in Question')
  // console.log(item)
  // console.log('obj:')
  // console.log(obj)
  obj.pickedItem = Object.assign({}, item)
  if (item.onlineBooking) {
    // LETSWAIT is a trick used by handleSignIn from login.vue in case to make the booking windows poping up.
    obj.navigateNext = 'LETSWAIT'
  }
  if (item.isFixedPrice) {
    // logger.info('FixedPrice item selected ' + JSON.stringify(item))
    const ProcessCreateFixedPriceRequest = () => {
        const userSub = getUserSub()
        // console.log('userSub', userSub)
        // We need to create this request in DB.
        var myRequestService = new RequestService()
        myRequestService.createFixedPriceRequest(userSub, item)
        .then((result) => {
          obj.dialogAuth = false
          if (item.onlineBooking) {
            // console.log('we deal with booking here')
            dealWithBooking(obj, item, result)
          } else {
            // console.log('we are doing the navigation to EndUserMyServices page')
            // We are going to the service page
            navigate(obj, 'EndUserMyServices')
          }
        })
        .catch(err => {
          logger.error(err)
          return false
        })
      }
    if (obj.userType !== obj.ENDUSER) {
      // console.log('we pass in authentication mode', obj.userType, obj.ENDUSER)
      // obj.question = {} REMOVED AS NO MORE MANAGED HERE
      obj.dialogAuth = true
      logger.info('user is not an Enduser.')
      readyItemsV1.push(ProcessCreateFixedPriceRequest)
    } else {
      // console.log('we pass in ALREADY authenticated mode', obj.userType, obj.ENDUSER)
      ProcessCreateFixedPriceRequest()
    }
  } else {
    // logger.info('Quotation based item selected ' + JSON.stringify(item))
    // console.log('Quotation based item selected ' + JSON.stringify(item))
    obj.qna = new QnA()
    obj.questions = obj.qna.fetchQuestions(item, locale)
    // obj.postalCodeId = postalCodeId REMOVED AS NO MORE MANAGED HERE for MiniWeb
    // console.log('Found questions ' + JSON.stringify(obj.questions))
    obj.questions.category = item.category
    obj.questions.subCategory = item.subCategory
    // obj.stepper = 1 REMOVED AS NO MORE MANAGED HERE for MiniWeb
    obj.dialogQuestions = true
  }
}

async function asyncFetchQuestions (categoryId, subCategoryId, catName, locale) {
  // TODO would be great merge all 3x fetchQuestions functions
  // - one from Hero
  // - one from QnA
  // - one here
  // console.log(categoryId)
  // console.log(subCategoryId)
  // console.log('obj.questions avant')
  try {
    const item = { isFixedPrice: false, subCategoryID: subCategoryId, categoryID: categoryId, name: catName }

    // logger.info('No service found, we are building the request.')
    // We are fetching the question from the sub category.

    var localQuestion = ''
    if (subCategoryId !== '') {
      localQuestion = await fetchASubCategoryReturnQuestion(subCategoryId, locale)
    }
    // console.log('localQuestion after sub cat')
    // console.log(localQuestion)

    // We are fetching the question from the category.
    if (localQuestion === '') {
      localQuestion = await fetchACategoryReturnQuestion(categoryId, locale)
    }

    // console.log('localQuestion after cat')
    // console.log(localQuestion)

    item.question = localQuestion
    return item
  } catch (err) {
    console.log(err)
  }
}

// Anonymous became an EndUser and we persist previously selected Service(s)
export function clearPersistQuestions () {
  readyItemsV1 = []
}

export function isPersistQuestionsEmpty () {
  if (readyItemsV1.length === 0) {
    return true
  }
  return false
}

// Anonymous became an EndUser and we persist previously selected Service(s)
export function persistQuestions (obj) {
  // console.log('readyItems:')
  // console.log(readyItems)
  for (var item of readyItemsV1) {
    // TODO should we deal with only 1 item ? --> This is managed by the flushing at the end of the function.
    item()
  }
  // After execution, we flush the list of points
  readyItemsV1 = []
  // console.log('readyItems after flush:')
  // console.log(readyItems)
}

// Anonymous or EndUser press Cancel button
export function cancelQuestions (obj, componentObj) {
    obj.dialogQuestions = false
    obj.dialogAuth = false
    // REMOVED obj.editedItem = componentObj.defaultItem // TO DO: TO CHECK THIS STORY OF EDIT ITEM - NO NEED from my point of view with Mini-Web at least
    // uncommenting following line means horrible things happening !
    // obj.questions = obj.defaultItem
    componentObj.question = {}
    componentObj.stepper = 0
    clearPersistQuestions()
}

// Retrieve inputs from Anonymous or EndUser
export function getAnswerFromUser (componentObj) {
    var value = null
    if (componentObj.question.type === 'MultipleSelect') {
      value = componentObj.valueMultipleSelect.toString()
    } else if (componentObj.question.type === 'OneSelect') {
      value = componentObj.valueOneSelect
    } else if (['InputText', 'InputNumber'].includes(componentObj.question.type)) {
      value = componentObj.valueInputText
      // logger.info('answer is ' + JSON.stringify(value))
    }
    return value || componentObj.question.default
}

// Set default answers or previous inputs to Anonymous or EndUser
export function setAnswerForUser (componentObj, value = null) {
    if (['MultipleSelect', 'OneSelect'].includes(componentObj.question.type)) {
      // We clean the previous answers
      componentObj.itemsSelect.splice(0, componentObj.itemsSelect.length)
      // we populate with the new proposed answers
      for (var q of componentObj.question.answers) {
        componentObj.itemsSelect.push(q.text)
      }
    }
    if (componentObj.question.type === 'MultipleSelect') {
      componentObj.valueMultipleSelect = value.split(',')
    }
    if (componentObj.question.type === 'OneSelect') {
      componentObj.valueOneSelect = value
    }
    if (['InputText', 'InputNumber'].includes(componentObj.question.type)) {
      componentObj.valueInputText = value
    }
}

// Moving to a new question - usually done by question id in the stepper component
export function nextStep (obj, componentObj, n = null) {
    // logger.info('moving to step number ' + n + ' stepper is now ' + obj.stepper)
    // console.log('I pass here 0: ', n)
    // console.log(obj.questions)
    if (obj.questions.question.length === 0) {
      // there is no questions to deal with
      // happens when a fixed price item is selected by an Anonymous
      return
    }
    // console.log('I pass here 1')
    if ('id' in componentObj.question) {
      // logger.info('storing answer ' + obj.getAnswerFromUser())
      // We store the customer answers if we're not in initial state
      obj.qna.storeAnswers(componentObj.getAnswerFromUser())
    }
    // console.log('I pass here 2')
    // if n is provided and within boundaries
    // OR if n is not provided and current question is followed by another one
    if ((n && n > 0 && n <= obj.questions.question.length) || (!n && componentObj.question.hasNext === true)) {
      componentObj.question = obj.qna.forward(n) || componentObj.defaultItem
      // logger.info('New question is ' + JSON.stringify(obj.question))

      // We validate that the end user never provided an answer
      if (componentObj.question.givenAnswer) {
        // The end user had an answer previously
        componentObj.setAnswerForUser(componentObj.question.givenAnswer)
      } else {
        // Setting the default value for the question
        componentObj.setAnswerForUser(componentObj.question.default)
      }
      // stepper may already be in good position
      componentObj.stepper = componentObj.question.index
    } else if ((n && n > 0) || (!n && componentObj.question.hasNext === false)) {
      // we filter out the case when n = 0 happening on cancellation

      const jsonReport = JSON.stringify(obj.qna.getReport())
      // logger.info('And the report is........................... ' + jsonReport)
      // we copy the item
      const item = Object.assign({}, obj.pickedItem)
      // and reset old one to default (for the sake of not leaving a mess ?)
      obj.pickedItem = componentObj.defaultItem

      // We verify if we have an empty item, and if this is the case, we it means that we are in the case of no answer, so we build a fake one to move forward.
      /*
      if ((item.questions.length === 0) && ( item.isFixedPrice === undefined)) {
        item.isFixedPrice = false
        item.subCategoryID =
        item.name =
        item.categoryID
      }
      */
      const ProcessCreateQuotationRequest = () => {
        const userSub = getUserSub()
        var myRequestService = new RequestService()
        myRequestService.createQuotationRequest(userSub, item, jsonReport, obj.postalCodeId, obj)
        .then(() => {
          obj.dialogAuth = false
          navigate(obj, 'EndUserMyServices')
        })
        .catch(err => {
          logger.error(err)
          return false
        })
      }
      if (obj.userType === obj.ENDUSER) {
        ProcessCreateQuotationRequest()
      } else {
        // report is ready to be persisted upon successful EndUser signin
        // readyItems.slice(0, readyItems.length) // We clean the array first
        readyItemsV1.push(ProcessCreateQuotationRequest)
        // we move to last step : authenticator
        componentObj.stepperFixed = 'C'
        obj.dialogAuth = true
        obj.dialogQuestions = false
      }
    }
}

// Moving to a question - 1
export function previousStep (obj, componentObj, n = null) {
    // logger.info('moving to step number ' + n + ' stepper is now ' + obj.stepper)
    // logger.info('storing answer ' + obj.getAnswerFromUser())
    // We store the customer answers
    obj.qna.storeAnswers(componentObj.getAnswerFromUser())

    componentObj.question = obj.qna.backward(n)

    // Setup the system with the previous answer
    componentObj.setAnswerForUser(componentObj.question.givenAnswer)

    componentObj.stepper = componentObj.question.index
}
