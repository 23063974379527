<!------------------------------------------------------------------------------
// KIDIWI Digital property
// Header Component to define the working employee time for a ressource or anything during the week. This is working in combination with WorktimeDay
// Props:
// - value is just an internal props for the management of the values of the weekWorkingDays
// - ressourceid: contains an id to identify the case of delete of a ressource.
// Example of usage:
        <kdw-dialog-resource-sub-worktime-Week
          v-model="weekWorkingDays"
          :ressourceid="resource.id"
        />
  with weekWorkingDays, which is an array where is row of the array correspond to a day of the week starting on monday.
       This is fixed way of managing the week.
       each row is containing a list of object indicating the working periof of the day and the break.
       1 single working period and as many break as you want.
      weekWorkingDays = [[{ type: 'Working', startHH: '10', startMM: '00', endHH: '17', endMM: '00' },
                        { type: 'Break', startHH: '12', startMM: '00', endHH: '14', endMM: '00' }],
                       [{ type: 'Working', startHH: '09', startMM: '00', endHH: '17', endMM: '00' },
                        { type: 'Break', startHH: '13', startMM: '00', endHH: '14', endMM: '00' }],
                       [{ type: 'Working', startHH: '09', startMM: '00', endHH: '17', endMM: '00' },
                        { type: 'Break', startHH: '13', startMM: '00', endHH: '14', endMM: '00' }],
                       [{ type: 'Working', startHH: '09', startMM: '00', endHH: '17', endMM: '00' },
                        { type: 'Break', startHH: '13', startMM: '00', endHH: '14', endMM: '00' }],
                       [{ type: 'Working', startHH: '09', startMM: '00', endHH: '17', endMM: '00' },
                        { type: 'Break', startHH: '13', startMM: '00', endHH: '14', endMM: '00' }],
                       [{ type: 'Working', startHH: '12', startMM: '00', endHH: '16', endMM: '00' },
                        { type: 'Break', startHH: '09', startMM: '00', endHH: '10', endMM: '00' }],
                       [{ type: 'Working', startHH: '12', startMM: '00', endHH: '16', endMM: '00' },
                        { type: 'Break', startHH: '08', startMM: '00', endHH: '12', endMM: '00' }],
    ]
// ------------------------------------------------------------------------------>

<template>
  <v-container>
    <!---- This is the header   --------------------------------------------->
    <v-row
      dense
      justify="end"
    >
      <!--
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            color="primary"
            dark
            small
            rounded
            v-bind="attrs"
            v-on="on"
            @click="copyMondayToWeekDay()"
          >
            <v-icon>
              mdi-content-duplicate
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('button.copyWorkingSlot5Days') }}</span>
      </v-tooltip>
      -->
      <kdw-icon-action-tooltip
        min-width="auto"
        tipkey="button.copyWorkingSlot5Days"
        @click="copyMondayToWeekDay()"
      >
        mdi-content-duplicate
      </kdw-icon-action-tooltip>
      <!--
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            color="primary"
            dark
            small
            rounded
            v-bind="attrs"
            v-on="on"
            @click="copyMondayToFullWeek()"
          >
            <v-icon>
              mdi-content-copy
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('button.copyWorkingSlotAllDays') }}</span>
      </v-tooltip>
      -->
      <kdw-icon-action-tooltip
        min-width="auto"
        tipkey="button.copyWorkingSlotAllDays"
        @click="copyMondayToFullWeek()"
      >
        mdi-content-copy
      </kdw-icon-action-tooltip>
    </v-row>
    <v-row>
      <kdw-dialog-resource-sub-worktime-week-sub-worktime-Day
        :key="'Monday' + trickDisplay"
        v-model="mondayWorkingHours"
        :day-name="$t('worktimeWeek.monday')"
        :ressourceid="ressourceIdLocal"
      />
      <kdw-dialog-resource-sub-worktime-week-sub-worktime-Day
        :key="'Tuesday' + trickDisplay"
        v-model="tuesdayWorkingHours"
        :day-name="$t('worktimeWeek.tuesday')"
        :ressourceid="ressourceIdLocal"
      />
      <kdw-dialog-resource-sub-worktime-week-sub-worktime-Day
        :key="'Wednesday' + trickDisplay"
        v-model="wednesdayWorkingHours"
        :day-name="$t('worktimeWeek.wednesday')"
        :ressourceid="ressourceIdLocal"
      />
      <kdw-dialog-resource-sub-worktime-week-sub-worktime-Day
        :key="'Thursday' + trickDisplay"
        v-model="thursdayWorkingHours"
        :day-name="$t('worktimeWeek.thursday')"
        :ressourceid="ressourceIdLocal"
      />
      <kdw-dialog-resource-sub-worktime-week-sub-worktime-Day
        :key="'Friday' + trickDisplay"
        v-model="fridayWorkingHours"
        :day-name="$t('worktimeWeek.friday')"
        :ressourceid="ressourceIdLocal"
      />
      <kdw-dialog-resource-sub-worktime-week-sub-worktime-Day
        :key="'Saturday' + trickDisplay"
        v-model="saturdayWorkingHours"
        :day-name="$t('worktimeWeek.saturday')"
        :ressourceid="ressourceIdLocal"
      />
      <kdw-dialog-resource-sub-worktime-week-sub-worktime-Day
        :key="'Sunday' + trickDisplay"
        v-model="sundayWorkingHours"
        :day-name="$t('worktimeWeek.sunday')"
        :ressourceid="ressourceIdLocal"
      />
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'WorktimeDay',

    props: {
      value: {
        type: Array,
        required: true,
      },
      ressourceid: {
        type: String,
        default: '',
      },
    },

    data: () => {
      return {
        workingWeek: [],
        mondayWorkingHours: [],
        tuesdayWorkingHours: [],
        wednesdayWorkingHours: [],
        thursdayWorkingHours: [],
        fridayWorkingHours: [],
        saturdayWorkingHours: [],
        sundayWorkingHours: [],
        trickDisplay: 0,
        ressourceIdLocal: '',
      }
    },

    watch: {
      mondayWorkingHours: function () {
        // If I see a change on mondayWorkingHours, this function is trigerred!!!
        // console.log('mondayWorkingHours updated @ son level', this.mondayWorkingHours)
        this.workingWeek[0] = [...this.mondayWorkingHours]
        this.emitResult()
      },
      tuesdayWorkingHours: function () {
        // If I see a change on mondayWorkingHours, this function is trigerred!!!
        // console.log('tuesdayWorkingHours updated @ son level', this.tuesdayWorkingHours)
        this.workingWeek[1] = [...this.tuesdayWorkingHours]
        this.emitResult()
      },
      wednesdayWorkingHours: function () {
        // If I see a change on mondayWorkingHours, this function is trigerred!!!
        // console.log('wednesdayWorkingHours updated @ son level', this.wednesdayWorkingHours)
        this.workingWeek[2] = [...this.wednesdayWorkingHours]
        this.emitResult()
      },
      thursdayWorkingHours: function () {
        // If I see a change on mondayWorkingHours, this function is trigerred!!!
        // console.log('thursdayWorkingHours updated @ son level', this.thursdayWorkingHours)
        this.workingWeek[3] = [...this.thursdayWorkingHours]
        this.emitResult()
      },
      fridayWorkingHours: function () {
        // If I see a change on mondayWorkingHours, this function is trigerred!!!
        // console.log('fridayWorkingHours updated @ son level', this.fridayWorkingHours)
        this.workingWeek[4] = [...this.fridayWorkingHours]
        this.emitResult()
      },
      saturdayWorkingHours: function () {
        // If I see a change on mondayWorkingHours, this function is trigerred!!!
        // console.log('saturdayWorkingHours updated @ son level', this.saturdayWorkingHours)
        this.workingWeek[5] = [...this.saturdayWorkingHours]
        this.emitResult()
      },
      sundayWorkingHours: function () {
        // If I see a change on mondayWorkingHours, this function is trigerred!!!
        // console.log('sundayWorkingHours updated @ son level', this.sundayWorkingHours)
        this.workingWeek[6] = [...this.sundayWorkingHours]
        this.emitResult()
      },
      value: function (newValue) {
        // We are checking to see if a delete of a ressource happened
        if (this.ressourceIdLocal !== this.ressourceid) {
          // console.log('delete dectected......................... in worktimeWeek')
          this.workingWeek = [...this.value]
          if (this.value.length === 7) {
            this.mondayWorkingHours = [...this.value[0]]
            this.tuesdayWorkingHours = [...this.value[1]]
            this.wednesdayWorkingHours = [...this.value[2]]
            this.thursdayWorkingHours = [...this.value[3]]
            this.fridayWorkingHours = [...this.value[4]]
            this.saturdayWorkingHours = [...this.value[5]]
            this.sundayWorkingHours = [...this.value[6]]
          }
          this.ressourceIdLocal = this.ressourceid
        }
      },
    },

    created: function () {
      // console.log(this.value)
      this.workingWeek = [...this.value]
      if (this.value.length === 7) {
        this.mondayWorkingHours = [...this.value[0]]
        this.tuesdayWorkingHours = [...this.value[1]]
        this.wednesdayWorkingHours = [...this.value[2]]
        this.thursdayWorkingHours = [...this.value[3]]
        this.fridayWorkingHours = [...this.value[4]]
        this.saturdayWorkingHours = [...this.value[5]]
        this.sundayWorkingHours = [...this.value[6]]
      }
      // console.log('this.$attr = ', this.$attrs)
      // console.log('this.$listeners = ', this.$listeners)
      this.ressourceIdLocal = this.ressourceid
    },

    methods: {
      emitResult () {
        this.$emit('input', this.workingWeek)
      },
      // Duplicate the Monday to tuesday-Friday
      copyMondayToWeekDay () {
        this.tuesdayWorkingHours = [...this.mondayWorkingHours]
        this.wednesdayWorkingHours = [...this.mondayWorkingHours]
        this.thursdayWorkingHours = [...this.mondayWorkingHours]
        this.fridayWorkingHours = [...this.mondayWorkingHours]
        // this.saturdayWorkingHours = [...this.mondayWorkingHours]
        // this.sundayWorkingHours = [...this.mondayWorkingHours]
        this.workingWeek[1] = [...this.mondayWorkingHours]
        this.workingWeek[2] = [...this.mondayWorkingHours]
        this.workingWeek[3] = [...this.mondayWorkingHours]
        this.workingWeek[4] = [...this.mondayWorkingHours]
        // this.workingWeek[5] = [...this.mondayWorkingHours]
        // this.workingWeek[6] = [...this.mondayWorkingHours]
        this.emitResult()
        this.$root.kdwsnackbar.alert(this.$i18n.t('AccountEU.alertEUSuccessUpdate'))
        this.trickDisplay++
      },
      // Duplicate the Monday to all the other days
      copyMondayToFullWeek () {
        this.tuesdayWorkingHours = [...this.mondayWorkingHours]
        this.wednesdayWorkingHours = [...this.mondayWorkingHours]
        this.thursdayWorkingHours = [...this.mondayWorkingHours]
        this.fridayWorkingHours = [...this.mondayWorkingHours]
        this.saturdayWorkingHours = [...this.mondayWorkingHours]
        this.sundayWorkingHours = [...this.mondayWorkingHours]
        this.workingWeek[1] = [...this.mondayWorkingHours]
        this.workingWeek[2] = [...this.mondayWorkingHours]
        this.workingWeek[3] = [...this.mondayWorkingHours]
        this.workingWeek[4] = [...this.mondayWorkingHours]
        this.workingWeek[5] = [...this.mondayWorkingHours]
        this.workingWeek[6] = [...this.mondayWorkingHours]
        this.emitResult()
        this.$root.kdwsnackbar.alert(this.$i18n.t('AccountEU.alertEUSuccessUpdate'))
        this.trickDisplay++
      },
    },

  }
</script>
