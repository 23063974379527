<!------------------------------------------------------------------------------
// KIDIWI Digital property
// This template is corresponding to the Elegant template, which is composed of 2 pages:
// -  A first presentation page with the company name on top left, a text below, and a series of pictures in
//    a coursel on desktop mode and in several lines on mobile. On the rigth, a column with a series of
//    informations like the logo, job name, location, price range, rating, contact number, address and the siret number
// -  A second page containing a second text with an additional presentation, the same bar on the left, and the
//    associated services linked to this company.
// ------------------------------------------------------------------------------>
<template>
  <base-section
    v-if="displayComponent()"
    id="mini-web"
    space="0"
  >
    <!-- we are building a nagigation drawer which is in fact a rigth bar fixed containing the company infos
      Logic is here: https://javascript.plainenglish.io/vuetify-v-app-e04a673c52
      Hidden from
    -->
    <v-card
      class="hidden-sm-and-down"
    >
      <v-navigation-drawer
        permanent
        right
        fixed
        color="transparent"
        class="mt-16"
        floating
      >
        <!-- Vertical bar with basic information -->
        <miniweb-template-elegant-general-info
          :data-template="dataTemplate"
          class="my-6 mr-3 rounded-xl"
        />
      </v-navigation-drawer>
    </v-card>
    <!-- We start the showroom -->
    <base-img
      id="ShowroomTag"
      :min-height="minHeight"
      :lazy-src="showroomPictureUrl"
      :gradient="buildGradientColorBackgroundShowroom()"
    >
      <v-container class="mt-15">
        <v-row>
          <v-col
            cols="12"
            sm="1"
          >
            <!-- this is the showroom shortcut -->
            <kdw-btn
              class="ma-2"
              :color="dataTemplate.template.themeColor"
              dark
              min-width="70"
              disabled
              @click="serviceToShowroom()"
            >
              <v-icon>mdi-eye</v-icon>
            </kdw-btn>
            <!-- this is the service shortcut -->
            <kdw-btn
              class="ma-2"
              :color="dataTemplate.template.themeColor"
              dark
              min-width="70"
              @click="showroomToService()"
            >
              <v-icon>mdi-fountain-pen-tip</v-icon>
            </kdw-btn>
          </v-col>
          <v-col
            cols="12"
            sm="10"
            md="8"
            lg="9"
            class="pl-5"
          >
            <span :class="'text-h3 font-weight-black '+ textColor + '--text'">{{ dataTemplate.miniwebData.identity.companyName }}</span><br>
            <span
              :class="'text-subtitle-1 '+ textColor + '--text'"
              v-html="dataTemplate.miniwebData.showroom.description"
            />
            <br>
            <!-- mode="2horizontal1vertical" , Carousel, 3horizontal-->
            <!-- for desktop -->
            <miniweb-picture-displayer
              mode="Carousel"
              :pictures="dataTemplate.miniwebData.showroom.pictures"
              class="hidden-sm-and-down"
              :loop="dataTemplate.miniwebData.showroom.picturesLoop"
            />
            <!-- for mobile -->
            <miniweb-picture-displayer
              mode="3horizontal"
              :pictures="dataTemplate.miniwebData.showroom.pictures"
              class="hidden-md-and-up"
            />
          </v-col>
        </v-row>
        <!-- This is the identity card o-->
        <v-row
          justify="center"
          class="hidden-md-and-up"
        >
          <v-col
            cols="12"
            sm="8"
            md="8"
          >
            <!-- Vertical bar with basic information -->
            <miniweb-template-elegant-general-info
              :data-template="dataTemplate"
              class="rounded-xl mx-auto"
            />
          </v-col>
        </v-row>
      </v-container>
    </base-img>
    <!-- We start the SERVICES -->
    <base-img
      id="ServicesTag"
      :min-height="minHeight"
      :gradient="buildGradientColorBackgroundServices()"
      :src="servicesPictureUrl"
    >
      <v-container class="mt-7">
        <v-row>
          <v-col
            cols="12"
            sm="1"
          >
            <kdw-btn
              class="ma-2"
              :color="dataTemplate.template.themeColor"
              dark
              min-width="70"
              @click="serviceToShowroom()"
            >
              <v-icon>mdi-eye</v-icon>
            </kdw-btn>
            <!-- this is the service shortcut -->
            <kdw-btn
              class="ma-2"
              :color="dataTemplate.template.themeColor"
              dark
              min-width="70"
              disabled
              @click="showroomToService()"
            >
              <v-icon>mdi-fountain-pen-tip</v-icon>
            </kdw-btn>
            <!--
            <miniweb-bouton-set
              :color="dataTemplate.template.themeColor"
              :buttons="[{ id: 'mdi-eye', disabled: false, display: true }, { id: 'mdi-star', disabled: false, display: (dataTemplate.miniwebData.ratings.score)? true : false }, { id: 'mdi-fountain-pen-tip', disabled: true, display: true }, ]"
            />
            -->
          </v-col>
          <v-col
            cols="12"
            sm="8"
            class="pl-5"
          >
            <span
              :class="'text-subtitle-1 '+ textColor + '--text'"
              v-html="dataTemplate.miniwebData.services.description"
            />
            <kdw-general-search-results-extension-based
              :search-event="searchResultFlag"
              :service-provider-id="dataTemplate.miniwebData.identity.serviceProviderID"
              @ServiceSelected="ServiceSelected($event)"
            />
          </v-col>
        </v-row>
      </v-container>
    </base-img>
    <!-- We start the DEBUG zone -->
    <base-img
      v-if="debug"
      :gradient="buildGradientColor()"
    >
      <v-container>
        <v-row>
          MiniWeb Page Elegant<br>
          {{ dataTemplate }}
        </v-row>
      </v-container>
    </base-img>
    <!--  List of templates to display the services -- Dialog associated -->
    <!--------     AUTHENTICATOR DIALOG    ----------------------------------------->
    <kdw-dialog-authenticator
      v-model="dialogAuth"
      :parent="this"
      :navigate-next="navigateNext"
    />
    <!--------     Questions DIALOG    ----------------------------------------->
    <kdw-dialog-questions
      v-model="dialogQuestions"
      :parent-obj="this"
      :questions="questions"
    />
    <!--------     booking DIALOG    ----------------------------------------->
    <kdw-dialog-booking
      v-model="dialogOnlineBooking"
      :service-id="ServiceIDforOnlineBooking"
      :answer-id="answerId"
      :navigation-object="navigationObjectThisPage"
    />
  </base-section>
</template>

<script>
  // import { getMiniwebData } from '@/services/miniweb/service'
  import { TEMPLATE_MINIWEB_LIST } from '@/services/miniweb/resolver'
  // import { isObjectEmpty } from '@/helpers/GenericFunctions'
  import { hexToRGB, isColorDark } from '@/helpers/Background'

  // Import for the services
  import { ANONYMOUS, ENDUSER, SERVICEPROVIDER, getUserType, onUserTypeChange } from '@/services/User'
  import {
    pickItem as CpickItem,
    persistQuestions as CpersistQuestions,
    clearPersistQuestions as CclearPersistQuestions,
    isPersistQuestionsEmpty as CisPersistQuestionsEmpty,
  } from '@/controllers/QuestionsV1'
  import { getPictureUrlfromS3Bucket } from '@/helpers/GenericFunctionsS3Download'

  export default {
    name: 'ElegantTemplate',
    metaInfo () {
      return {
        title: this.title,
      }
    },
    props: {
      dataTemplate: {
        type: Object,
      },
    },
    data: () => ({
      debug: false,
      title: '',
      // DATA FOR SERVICES TO LIST AND POPUP
      // Authenticator Dialog variables
      dialogAuth: false,
      ENDUSER: ENDUSER,
      ANONYMOUS: ANONYMOUS,
      navigateNext: 'HOME',

      // Question Dialog variable
      dialogQuestions: false,
      questions: { question: [], category: '', subCategory: '' }, // all questions, default value to pre-render
      pickedItem: { name: '' },

      // Variables for the boooking dialog
      dialogOnlineBooking: false,
      ServiceIDforOnlineBooking: '',
      answerId: '',
      navigationObjectThisPage: null,
      // Complementary Variables for create method
      userType: null,
      signInConfig: {},
      signupFormFields: [],
      // Variable for Serach Dialog
      searchResultFlag: false,

      // -----------------------------------------------
      // Data for the display of this template
      showroomPictureUrl: null,
      servicesPictureUrl: null,
      textColor: 'black',
    }),
    computed: {
      minHeight () {
        const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh'

        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },
    },
    created: function () {
      // console.log('dataTemplate')
      // console.log(this.dataTemplate)
      // setTimeout(() => { this.searchResultFlag = true }, 1000)
      this.navigationObjectThisPage = this

      // Logic for the Dialog Questions:
      this.ANONYMOUS = ANONYMOUS
      this.ENDUSER = ENDUSER
      this.locale = this.$i18n.locale
      this.userType = getUserType()
      this.unsubscribeUserType = onUserTypeChange((newUserType) => {
        console.log('changing userType in miniweb ', newUserType)
        this.userType = newUserType
        if (this.userType === ENDUSER) {
          // console.log('hero - Seen as a User - Cpersist is run')
          CpersistQuestions()
        } else if (this.userType === SERVICEPROVIDER) {
          if (CisPersistQuestionsEmpty() === false) {
            // console.log('hero - Seen as a SP - Cpersist is not run & clear the array')
            CclearPersistQuestions()
            this.$root.kdwsnackbar.alert(this.$i18n.t('hero.alertAuthenticatedNotEU'), -1)
            // we have to keep an alert if not the snackbar is not visible.
            // alert(this.$i18n.t('hero.alertAuthenticatedNotEU'))
          }
        }
        // ignore going to null or Anonymous or SP ?
        // console.log('hero - Seen as a ?? - Cpersist is not run')
      })
      // This functions are specific to this Template page ---------------------
    },
    // We are using beforeUpdate to address the rigth hook to give time to collect the data. From Created it does not work.
    beforeUpdate: function () {
      // console.log('this.dataTemplate')
      // console.log(this.dataTemplate)
      // We are loading the picture of the showroom background
      if (this.dataTemplate.miniwebData && this.dataTemplate.miniwebData.showroom && this.dataTemplate.miniwebData.showroom.backgroundPicture) {
        // console.log('background picture Name:', this.dataTemplate.miniwebData.showroom.backgroundPicture)
        getPictureUrlfromS3Bucket(this.dataTemplate.miniwebData.showroom.backgroundPicture)
          .then((url) => {
            this.showroomPictureUrl = url
            // console.log('background picture showroom URL:', url)
          })
          .catch(err => console.error(err))
      }
      // We are loading the picture of the services background
      if (this.dataTemplate.miniwebData && this.dataTemplate.miniwebData.services && this.dataTemplate.miniwebData.services.backgroundPicture) {
        // console.log('background picture Name:', this.dataTemplate.miniwebData.showroom.backgroundPicture)
        getPictureUrlfromS3Bucket(this.dataTemplate.miniwebData.services.backgroundPicture)
          .then((url) => {
            // console.log('logoUrl:', url)
            this.servicesPictureUrl = url
            // console.log('background picture URL:', url)
          })
          .catch(err => console.error(err))
      }
      if (this.dataTemplate.miniwebData) this.textColor = this.getTextColor()
      this.title = this.dataTemplate.miniwebData.identity.companyName
      // we are shifting the launch of the service search.
      setTimeout(() => { this.searchResultFlag = true }, 1000)
    },
    methods: {
      displayComponent () {
        if (this.dataTemplate && this.dataTemplate.template && this.dataTemplate.template.name === TEMPLATE_MINIWEB_LIST[1].name) return true
        return false
      },
      buildGradientColorBackgroundShowroom () {
        if (this.dataTemplate.miniwebData && this.dataTemplate.miniwebData.showroom && this.dataTemplate.miniwebData.showroom.backgroundPicture) {
          const arrayColor = hexToRGB(this.dataTemplate.template.themeColor)
          if (arrayColor.length === 3) {
            // we are using the color of the theme
            return 'to bottom, rgba(' + arrayColor[0] + ',' + arrayColor[1] + ',' + arrayColor[2] + ',.7), rgba(255,255,255,.1)'
          } else return 'to bottom, rgba(11, 28, 61, .7), rgba(255,255,255,.1)' // little overlay gray
        }
        return 'to bottom, ' + this.dataTemplate.template.themeColor + ', rgba(255,255,255,1)'
      },
      buildGradientColorBackgroundServices () {
        if (this.dataTemplate.miniwebData && this.dataTemplate.miniwebData.services && this.dataTemplate.miniwebData.services.backgroundPicture) {
          const arrayColor = hexToRGB(this.dataTemplate.template.themeColor)
          if (arrayColor.length === 3) {
            // we are using the color of the theme
            return 'to bottom, rgba(' + arrayColor[0] + ',' + arrayColor[1] + ',' + arrayColor[2] + ',.7), rgba(255,255,255,.1)'
          } else return 'to bottom, rgba(11, 28, 61, .7), rgba(255,255,255,.1)' // little overlay gray
        }
        return 'to bottom, ' + this.dataTemplate.template.themeColor + ', rgba(255,255,255,1)'
      },
      getTextColor () {
        if (isColorDark(this.dataTemplate.template.themeColor)) {
          return 'white'
        } else {
          return 'black'
        }
      },
      buildGradientColor () {
        return 'to bottom, ' + this.dataTemplate.template.themeColor + ', rgba(255,255,255,1)'
      },
      // METHODS for service selection - Mandatory in every template
      ServiceSelected (serviceSelected) {
        // console.log('serviceSelected')
        // console.log(serviceSelected)
        return CpickItem(this, serviceSelected, this.postalCodeId, this.$i18n.locale)
      },
      cancelQuestions () {
        return true
      },
      // Methods specific to this page
      showroomToService () {
        this.$vuetify.goTo('#ServicesTag', {
          duration: 1000,
          offset: 0,
          easing: 'easeInOutQuint',
        })
      },
      serviceToShowroom () {
        this.$vuetify.goTo('#ShowroomTag', {
          duration: 1000,
          offset: 0,
          easing: 'easeInOutQuint',
        })
      },
    },
  }
</script>
